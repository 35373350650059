<template>
  <b-row>
    <b-col md="4">
      <b-card-normal title="Sosyal Medya Tanımlama" :showLoading="show">
        <template v-slot:body>
          <tanimlama @showLoading="show = $event" @refresh="handlerGetData" :update="updateData" />
        </template>
      </b-card-normal>
    </b-col>
    <b-col md="8" order-sm="12">
      <b-card-normal title="Sosyal Medya Listesi" :showLoading="show">
        <template v-slot:body>
          <list :data="rows" @update="handlerUpdate($event)" @refresh="handlerGetData" />
        </template>
      </b-card-normal>
    </b-col>
  </b-row>
</template>

<script>
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
import Tanimlama from './component/tanimla.vue';
import List from './component/listele.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    BCardNormal,
    Tanimlama,
    List,
  },
  data: () => ({
    show: false,
    rows: [],
    updateData: {},
  }),
  computed: {
    ...mapGetters(['getSosyalMedyaListele']),
  },
  async created() {
    await this.$store.dispatch('sosyalMedyaListele');
    this.handlerGetData();
  },
  methods: {
    async handlerGetData() {
      this.show = true;
      this.rows = await this.getSosyalMedyaListele;
      this.show = false;
    },
    handlerUpdate(event) {
      this.updateData = event;
    },
  },
};
</script>

<style lang="scss" scoped></style>
